import React from 'react'
import { useScrollAnim } from "src/components/hooks/hooks"


export function SustainabilityFocus({ data }) {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main bg-light" ref={trigger}>
            <div className="container mw-xl">
                <h2 className={`mb-3 ${anim(1)}`}>{data?.title}</h2>
            </div>
            <div className="container mw-xl px-sm-down-0">
                <div className="row">
                    {data?.list?.map((item, i) => {
                        return (
                            <div key={i} className={`row pt-4 ${anim(2 + i)}`}>
                                <div className="col-md-6 col-lg-4">
                                    <img src={item?.image?.sourceUrl} alt={item?.image?.altText ? item?.image?.altText : "Image"} className="img-fluid w-100" />
                                </div>
                                <div className="col-md-6 col-lg-7 mt-sm-down-4">
                                    <div className="container d-lg-flex flex-column justify-content-center h-100">
                                        <h5>{item?.title}</h5>
                                        <p>{item?.text}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
