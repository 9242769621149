import React from 'react'
import { useScrollAnim } from "src/components/hooks/hooks"

import { SliderDuti } from "components/anti/slider/slider-duti"

export function SustainabilityCommunityProgram({ data }) {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main" ref={trigger}>
            <div className="container mw-xl">
                <h2 className={`${anim(1)}`}>{data?.title}</h2>
            </div>
            <div className="container mw-xl px-sm-down-0">
                <SliderDuti
                    className="gallery-sustainabiliity pt-4"
                    values={data?.images}
                />
                <div className="row row-4 mt-4">
                    {data?.list?.map((item, i) => {
                        return (
                            <div key={i} className={`col-md-6 my-4 ${anim(2 + i)}`}>
                                <div className="container">
                                    <h6>{item?.title}</h6>
                                    <p className="text-gray-600 font-weight-light">
                                        {item?.text}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
