import React, { useState, useEffect, useRef } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import { useScrollAnim } from "src/components/hooks/hooks"


gsap.registerPlugin(ScrollTrigger)
export const SustainabilityHeadline = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    const txtRunning = useRef(null)
    const [tl, setTl] = useState(
        gsap.timeline({
            paused: true,
        })
    )

    useEffect(() => {
        tl.from(txtRunning.current, {
            x: "50%",
            scrollTrigger: {
                trigger: txtRunning.current,
                start: "top 70%",
                end: "bottom",
                // markers: true,
                scrub: 1.5,
                toggleActions: "play none none reverse",
            },
        })
    }, [])
    return (
        <section className="py-main position-relative" ref={trigger}>
            <span ref={txtRunning} className="text-running text-gray-50">
                sustainability
            </span>
            <div className="container mw-xl">
                <div className="w-md-600px">
                    <p className={`font-weight-light text-gray-600 ${anim(1)}`}>{data?.text}</p>
                </div>
            </div>
        </section>
    )
}