import React from "react"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { SustainabilityHeadline } from "components/pages/sustainability/headline"
import { SustainabilityFocus } from "components/pages/sustainability/sustainable-community/focus"
import { SustainabilityCommunityProgram } from "components/pages/sustainability/sustainable-community/community-program"


const SustainableCommunity = ({ pageContext, location }) => {
  const pageData = pageContext?.data
  const lang = pageContext?.langKey

  const {
    cover,
    headline,
    ourFocusArea,
    loyalCommunityProgram
  } = lang === "en"
      ? pageData?.sustainabilityProgramsPage
      : pageData?.translation?.sustainabilityProgramsPage
      || pageData?.sustainabilityProgramsPage

  const bgColor = lang === "en"
    ? pageData?.backgroundColor?.backgroundColor?.color
    : pageData?.translation?.backgroundColor?.backgroundColor?.color
    || pageData?.backgroundColor?.backgroundColor?.color

  const { seo } = lang === "en"
    ? pageData
    : pageData?.translation
    || pageData

  const SEODefaultImg = cover?.image?.sourceUrl


  return (
    <Layout theme={bgColor} path={location?.pathname} lang={lang}>
      <SEO
        title={seo?.title}
        desc={seo?.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo?.opengraphTitle}
        fbDesc={seo?.opengraphDescription}
        url={location?.href}
        twitterTitle={seo?.twitterTitle}
        twitterDesc={seo?.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={cover} theme={bgColor} breadcrumb={location?.pathname} />
      <SustainabilityHeadline data={headline} />
      <SustainabilityFocus data={ourFocusArea} />
      <SustainabilityCommunityProgram data={loyalCommunityProgram} />
    </Layout>
  )
}

export default SustainableCommunity